
  import { Component, Vue } from 'vue-property-decorator';
  import { LoginValidationDto } from '../interfaces/LoginValidationDto';
  import { AuthenticationService } from '../services/authentication.service';
  import ImageCropper from './ImageCropper.component.vue';
  import CustomImage from './CustomImage.component.vue';

  /**
   * @desc composant permettant la selection d'une image pour les rubriques / classes
   */
  @Component({
    components: {
      'image-cropper': ImageCropper,
      'c-img' : CustomImage,
    },
  })
  export default class RubricImageSelector extends Vue {

    private _hasChanged = false;
    private dataUrl = "";
    private imgPreview : CustomImage | null = null;

    private imgSrc = "";

    /**
     * @desc appelée à la création du composant, écoute les événements venant de l'image cropper
     */
    mounted() :void {
      setTimeout(() => {
        this.setImgPreview();
      }, 200); 
    }

    /**
     * @desc défini la variable contenant le composant d'affichage de l'image
     */
    private setImgPreview() : void {
      this.imgPreview = this.$refs.imgPreview as CustomImage;
    }

    /**
     * @desc applique un click sur le file input, ce qui permet de choisir
     *  une image dans les fichiers de l'ordinateur
     */
    private browseImg() {
      (document.getElementById("imgInput")as any).click();
    }

    private previousSrc = "";
    /**
     * @desc met à jour l'image affichée
     */
    private imgChange() {
      const imgInput = document.getElementById("imgInput") as any;
      if(imgInput.files && imgInput.files[0]) {
        var urlCreator = window.URL || window.webkitURL;
        var imageUrl = urlCreator.createObjectURL(imgInput.files[0]);
        this.imgSrc = imageUrl;
        (this.$refs.imgcropper as ImageCropper).show(this.imgSrc, true, true);
      }
      else{
        if(this.previousSrc) {
          (this.imgPreview as CustomImage).setSrc(this.previousSrc);
        }
        else {
          this.imgSrc = "";
          (this.imgPreview as CustomImage).setSrc("");
        }
      }
    }

    /**
     * @desc affiche l'image contenue dans le dataUrl
     */
    private imageCropped(dataUrl : string) {
      if(dataUrl){
        this.dataUrl = dataUrl;
        this.previousSrc = dataUrl;
        (this.imgPreview as CustomImage).setSrc(dataUrl);
        this._hasChanged = true;
      }
      else {
        (this.imgPreview as CustomImage).setSrc("");
        if(this.previousSrc) {
          (this.imgPreview as CustomImage).setSrc(this.previousSrc);
        }
      } 
    }

    /**
     * @desc détruit l'image affichée en preview
     */
    private removeImg() {
      const imgInput = document.getElementById("imgInput") as any;
      imgInput.value = "";
      (this.imgPreview as CustomImage).setSrc("");
      this.previousSrc = "";
      this._hasChanged = true;
      this.dataUrl = "";
    }


    ////////////////   PUBLIC METHODS    /////////////////////

    /**
     * @desc charge l'image indiquée
     */
    public loadImg(imgId : number) : void {
      this.previousSrc = "";
      this._hasChanged = false;
      if(!this.imgPreview) this.setImgPreview();
      (this.imgPreview as CustomImage).setId(imgId);
    }

    /**
     * @desc renvoie s'il y a eu un changement dans l'image depuis le dernier chargement
     */
    public hasChanged() : boolean {
      return this._hasChanged;
    }

    /**
     * @desc renvoie l'image selectionnée en data Url
     */
    public getDataUrl() : string {
      return this.dataUrl;
    }
  }


