
  import { Utils } from '@/scripts/Utils';
import { Component, Prop, Vue } from 'vue-property-decorator';

  /**
   * @desc composant permettant la selection des dates 
   * de début / fin / archivation d'une rubrique / classe
   */
  @Component({
    components: {},
  })
  export default class RubricDatesLine extends Vue {
    outputStartDate = this.toTextFieldDate(new Date());
    outputEndDate = this.toTextFieldDate(new Date());
    outputArchivedDate = this.toTextFieldDate(new Date());

    endDateError = false;
    endDateErrorMsgs : string[] = [];
    archivedDateMessages : string[] = [];

    addYearArchivedDate = true;

    @Prop({default: 0})
    yearsToAdd? : number;

    /**
     * @desc initialisation de la ligne de dates
     * @param dates : objet contenant startDate, endDate et archivedDate de la rubrique
     * @param addYearArchivedDate : s'il faut précocher la case d'ajout d'une année scolaire
     */
    init(dates: any, addYearArchivedDate : boolean) : void {
      this.addYearArchivedDate = addYearArchivedDate;

      this.outputStartDate = this.toTextFieldDate(dates.startDate);
      this.outputEndDate = this.toTextFieldDate(dates.endDate);
      this.outputArchivedDate = this.toTextFieldDate(dates.archivedDate);

      this.checkArchivedDate();
    }

    /**
     * @desc transforme une Date en un string lisible pour la zone de texte : yyyy-mm-dd
     */
    private toTextFieldDate(date: Date) : string {
      let month = (date.getMonth()+ 1).toString();
      if(month.length === 1) month = "0" + month;
      let day = date.getDate().toString();
      if(day.length === 1) day = "0" + day;

      return date.getFullYear().toString() + '-' + month + '-' + day;
    }

    /**
     * @desc quand la date de début est modifiée, appelle une mise à jour 
     * de la validation de la date de fin
     */
    private startDateChanged() {
      this.triggerFocusOut((this.$refs.endDateField as any));
    }

    /**
     * @desc appelle une mise à jour de la validation de la date de fin
     */
    private triggerFocusOut(el : any) {
      el.focus();
      el.blur();
    }

    /**
     * @desc règles appliquées à la zone de texte de date de fin
     */
    private endDateRules() : any {
      const startDate = new Date(this.outputStartDate);
      const endDate = new Date(this.outputEndDate);

      if(this.addYearArchivedDate) {
        const archivedDate = Utils.addSchoolYear(endDate, this.yearsToAdd);
        if(this.toTextFieldDate(archivedDate) !== this.outputArchivedDate) {
          setTimeout(() => {
            this.outputArchivedDate = this.toTextFieldDate(new Date());
            this.outputArchivedDate = this.toTextFieldDate(archivedDate);
          }, 5);
        }
      }

      if(endDate < startDate){
        return("/!\\ La date de fin précède la date de début");
      }
      return true;
    }

    /**
     * @desc règle appliquée à la zone de texte de la date d'archivation
     */
    checkArchivedDate() {
      const today =  new Date(this.toTextFieldDate(new Date()));
      let archivedDate = new Date(this.outputArchivedDate);


      if(!this.addYearArchivedDate && archivedDate < today) {
        this.archivedDateMessages = ["/!\\ La rubrique sera archivée si vous validez"];
      }
      else{
         this.archivedDateMessages = [];
      }
    }

    /**
     * @desc renvoie les données collectées par le composant
     * @return dates : objet contenant startDate, endDate et archivedDate de la rubrique
     */
    getDates() : any {
      return {
        startDate: new Date(this.outputStartDate),
        endDate: new Date(this.outputEndDate),
        archivedDate: new Date(this.outputArchivedDate),
      }
    }

    /**
     * @desc vérifie si le composant comporte une erreur.
     */
    hasError() : boolean {
      if((this.$refs.endDateField as any).hasError) return true;

      return false;
    }
  }


